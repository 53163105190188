'use client';

import { ReactNode, RefObject } from 'react';
import Link from 'next/link';

import { Page, Post } from '@/payload-types';
import { ButtonProps } from '../Button';
import { addLeadEventToGTM } from '@/utils/tracking';
import { generateHref } from '@/utils/generateHref';

type PageReference = {
  value: string | Page;
  relationTo: 'pages';
};

type PostReference = {
  value: string | Post;
  relationTo: 'posts';
};

export type LinkType = 'reference' | 'custom' | null;
export type Reference = PageReference | PostReference | null;

export type CMSLinkType = {
  type?: LinkType;
  newTab?: boolean | null;
  reference?:
    | ({
        relationTo: 'pages';
        value: string | Page;
      } | null)
    | ({
        relationTo: 'posts';
        value: string | Post;
      } | null);
  url?: string | null;
  label?: string | null;
  children?: ReactNode;
  fullWidth?: boolean;
  mobileFullWidth?: boolean;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  buttonProps?: ButtonProps;
  buttonRef?: RefObject<HTMLAnchorElement>;
  event?: ('none' | 'event_02' | 'event_11' | 'event_17' | 'event_26' | 'event_27') | null;
  id?: string | null;
};

export type GenerateSlugType = {
  type?: LinkType;
  url?: string;
  reference?: Reference;
};

export const CMSLink = ({
  type,
  url,
  newTab,
  reference,
  label,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  buttonRef,
  event,
  id
}: CMSLinkType) => {
  if (!url) {
    return null;
  }

  let href = generateHref({ type, url, reference });
  const clickHandler = () => event && addLeadEventToGTM(event);

  if (!href) {
    return (
      <span
        id={id || undefined}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={clickHandler}
      >
        {label}
        {children}
      </span>
    );
  }

  const hrefIsLocal = ['tel:', 'mailto:', '/'].some(prefix => href.startsWith(prefix));

  if (!hrefIsLocal) {
    try {
      const objectURL = new URL(href);
      if (objectURL.origin === process.env.NEXT_PUBLIC_SITE_URL) {
        href = objectURL.href.replace(process.env.NEXT_PUBLIC_SITE_URL, '');
      }
    } catch (e) {
      console.error(`Failed to format url: ${href}`, e); // eslint-disable-line no-console
    }
  }

  const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  if (href.indexOf('/') === 0) {
    return (
      <Link
        id={id || undefined}
        href={href}
        {...newTabProps}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        prefetch={false}
        ref={buttonRef}
        onClick={clickHandler}
      >
        {label && label}
        {children && children}
      </Link>
    );
  }

  return (
    <a
      id={id || undefined}
      href={url}
      {...newTabProps}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={clickHandler}
    >
      {label && label}
      {children && children}
    </a>
  );
};
