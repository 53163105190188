import React, { Fragment } from 'react';
import escapeHTML from 'escape-html';
import Link from 'next/link';
import { TextAlign } from 'sharp';
import { toKebabCase } from '@/utils/to-kebab-case';
import { Media } from '@/components/Media';
import { Media as MediaType } from '@/payload-types';
import { applyAliases } from '@/utils/applyAliases';

type Node = {
  type: string;
  value?: MediaType;
  children?: Node[];
  url?: string;
  [key: string]: unknown;
  newTab?: boolean;
};

export type CustomRenderers = {
  [key: string]: (args: { node: Node; Serialize: SerializeFunction; index: number }) => JSX.Element; // eslint-disable-line
};

type SerializeFunction = React.FC<{
  content?: Node[];
  customRenderers?: CustomRenderers;
}>;

const isText = (value: unknown): boolean => {
  return typeof value === 'object' && value !== null && typeof (value as { text?: unknown }).text === 'string';
};

const getId = (node: Node): string | undefined => {
  return node.children && toKebabCase(node.children.map(child => child.text).join(''));
};

export const Serialize: SerializeFunction = ({ content, customRenderers }) => {
  return (
    <>
      {content?.map((node, i) => {
        let textAlign: TextAlign = 'left';

        if (node.format === 'center') {
          textAlign = 'center';
        } else if (node.format === 'end') {
          textAlign = 'right';
        }

        if (isText(node)) {
          node.text = applyAliases(node.text as string);

          let text = (
            <span style={{ textAlign }} dangerouslySetInnerHTML={{ __html: escapeHTML(node.text as string) }} />
          );

          if (node.format === 1) {
            text = (
              <strong style={{ textAlign }} key={i}>
                {text}
              </strong>
            );
          }

          if (node.code) {
            text = (
              <code style={{ textAlign }} key={i}>
                {text}
              </code>
            );
          }

          if (node.italic) {
            text = (
              <em style={{ textAlign }} key={i}>
                {text}
              </em>
            );
          }

          if (node.underline) {
            text = (
              <span style={{ textDecoration: 'underline', textAlign }} key={i}>
                {text}
              </span>
            );
          }

          if (node.strikethrough) {
            text = (
              <span style={{ textDecoration: 'line-through', textAlign }} key={i}>
                {text}
              </span>
            );
          }

          return (
            <>
              <Fragment key={i}>{text}</Fragment>
            </>
          );
        }

        if (!node) {
          return null;
        }

        if (customRenderers && customRenderers[node.type] && typeof customRenderers[node.type] === 'function') {
          return customRenderers[node.type]({ node, Serialize, index: i });
        }

        if (node.type === 'link') {
          return (
            <>
              <Link
                href={escapeHTML((node.fields as { url: string }).url)}
                key={i}
                {...(node.newTab
                  ? {
                      target: '_blank',
                      rel: 'noopener noreferrer'
                    }
                  : {})}
                style={{ textAlign }}
              >
                <Serialize content={node.children} customRenderers={customRenderers} />
              </Link>
            </>
          );
        }

        if (node.type === 'upload' && node.relationTo === 'media' && node.value) {
          return (
            <>
              <Media source={node.value} width={Number(node.value.width)} height={Number(node.value.height)} />
            </>
          );
        }

        switch (node.tag) {
          case 'br':
            return <br key={i} />;

          case 'h1':
            return (
              <h1 style={{ textAlign }} key={i} id={getId(node)}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </h1>
            );

          case 'h2':
            return (
              <h2 id={getId(node)} style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </h2>
            );

          case 'h3':
            return (
              <h3 id={getId(node)} style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </h3>
            );

          case 'h4':
            return (
              <h4 id={getId(node)} style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </h4>
            );

          case 'h5':
            return (
              <h5 id={getId(node)} style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </h5>
            );

          case 'h6':
            return (
              <h6 id={getId(node)} style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </h6>
            );

          case 'quote':
            return (
              <blockquote style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </blockquote>
            );

          case 'ul':
            return (
              <ul style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </ul>
            );

          case 'ol':
            return (
              <ol style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </ol>
            );

          case 'li':
            return (
              <li style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </li>
            );

          default:
            if (node.type === 'list') {
              return (
                <ul style={{ textAlign }} key={i}>
                  <Serialize content={node.children} customRenderers={customRenderers} />
                </ul>
              );
            }

            if (node.type === 'listitem') {
              return (
                <li style={{ textAlign }} key={i}>
                  <Serialize content={node.children} customRenderers={customRenderers} />
                </li>
              );
            }

            return (
              <p style={{ textAlign }} key={i}>
                <Serialize content={node.children} customRenderers={customRenderers} />
              </p>
            );
        }
      })}
    </>
  );
};
