import { Page } from '@/payload-types';
import { GenerateSlugType } from '@/components/CMSLink';

export const generateHref = (args: GenerateSlugType): string => {
  const { reference, url, type } = args;

  if ((type === 'custom' || type === undefined) && url) {
    return url;
  }

  if (type === 'reference' && reference?.value && typeof reference.value !== 'string') {
    if (reference.relationTo === 'pages') {
      const value = reference.value as Page;
      const breadcrumbs = value?.breadcrumbs;
      const hasBreadcrumbs = breadcrumbs && Array.isArray(breadcrumbs) && breadcrumbs.length > 0;
      if (hasBreadcrumbs) {
        return breadcrumbs[breadcrumbs.length - 1]?.url as string;
      }
    }

    return `/${reference.relationTo}/${reference.value.slug}`;
  }

  return '';
};
