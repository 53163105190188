type DeadlineConfig = {
  month: number;
  day: number;
};

type Alias = {
  name: string;
  value: string;
};

const DEFAULT_DEADLINE_DATE: DeadlineConfig = {
  month: 3, // April
  day: 15
};

const DEFAULT_EXTENDED_DEADLINE_DATE: DeadlineConfig = {
  month: 9, // October
  day: 15
};

const getDeadline = (year: number, config: DeadlineConfig) => {
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(config.month, config.day);

  if (date.getDay() === 6) {
    // Saturday
    date.setDate(config.day + 2); // Monday
  }

  if (date.getDay() === 0) {
    // Sunday
    date.setDate(config.day + 1); // Monday
  }

  return date;
};

const getNextDeadline = (config: DeadlineConfig) => {
  const currentYearDeadline = getDeadline(new Date().getFullYear(), config);

  if (currentYearDeadline.valueOf() < new Date().valueOf()) {
    // If deadline is in the paste show next year deadline
    return getDeadline(new Date().getFullYear() + 1, config);
  }

  return currentYearDeadline;
};

const getNextTaxDeadline = () => {
  return getNextDeadline(DEFAULT_DEADLINE_DATE);
};

const getNextExtendedTaxDeadline = () => {
  return getNextDeadline(DEFAULT_EXTENDED_DEADLINE_DATE);
};

const getOrdinalSuffix = (date: number) => {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const formatDate = (dateObj: Date, showYear: boolean = false) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const date = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  if (showYear) return `${month} ${date}${getOrdinalSuffix(date)}, ${year}`;

  return `${month} ${date}${getOrdinalSuffix(date)}`;
};

const getAliasesValues = () => {
  const nextTaxDeadline = getNextTaxDeadline();
  const nextExtendedTaxDeadline = getNextExtendedTaxDeadline();

  const aliases: Alias[] = [
    {
      name: '{{tax_deadline_day}}',
      value: formatDate(nextTaxDeadline)
    },
    {
      name: '{{tax_deadline_full}}',
      value: formatDate(nextTaxDeadline, true)
    },
    {
      name: '{{extended_tax_deadline_day}}',
      value: formatDate(nextExtendedTaxDeadline)
    },
    {
      name: '{{extended_tax_deadline_full}}',
      value: formatDate(nextExtendedTaxDeadline, true)
    },
    {
      name: '{{current_year}}',
      value: new Date().getFullYear().toString()
    }
  ];

  return aliases;
};

export const applyAliases = (text: string): string => {
  const aliases = getAliasesValues();

  return aliases.reduce((updatedText, { name, value }) => updatedText.replaceAll(name, value), text);
};
