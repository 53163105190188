'use client';

import { useContext, ReactNode } from 'react';
import { GlobalsContext } from '@/providers/Globals';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';

const Template = {
  Basic: 'basic',
  Blog: 'blog'
};

type Template = (typeof Template)[keyof typeof Template];

export const Layout = ({
  children,
  template,
  updatedAt,
  persistOnClickLogo = false,
  hideHeaderButton = false
}: {
  children: ReactNode;
  template: Template;
  updatedAt?: string;
  persistOnClickLogo?: boolean;
  hideHeaderButton?: boolean;
}) => {
  const { header, footer, generationDate } = useContext(GlobalsContext) || {};

  if (header) {
    header.persistOnClickLogo = persistOnClickLogo;
    header.hideHeaderButton = hideHeaderButton;
  }

  return (
    <>
      {header && <Header {...header} />}
      <main className={template}>{children}</main>
      <Footer {...footer} id={String(footer?.id)} generationDate={generationDate || ''} updatedAt={updatedAt} />
    </>
  );
};
