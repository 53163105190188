import React from 'react';
import classes from './index.module.scss';
import { Footer as FooterType } from '@/payload-types';
import { RichText } from '@/components/RichText';
import { CMSLink } from '@/components/CMSLink';
import packageData from '../../../package.json';
import { Media } from '@/components/Media';
import { clsx } from 'clsx';

type FooterProps = FooterType & {
  generationDate: string;
  updatedAt?: string;
};

export function Footer({ image, description, copyright, icons, menus, generationDate, updatedAt }: FooterProps) {
  const version = packageData.version || '';
  const formVersion = packageData.dependencies['@atomicleads/ext-satellite-form']?.replace('^', '') || '';

  return (
    <>
      <footer className={classes.section}>
        <div className="container">
          <div className={classes.row}>
            <div className={clsx(classes.col, classes.big)}>
              {image && <Media source={image} className={classes.logo} width="200" height="38" />}

              {description && (
                <div className={classes.disclaimers}>
                  <RichText content={description} />
                </div>
              )}
            </div>

            {icons?.length || menus?.length ? (
              <div className={clsx(classes.col, classes.small)}>
                {icons?.length && (
                  <div className={classes.icons}>
                    {icons.map(
                      ({ icon }, index) =>
                        icon && (
                          <div className={classes.iconWrapper} key={index}>
                            <Media source={icon} width="59" height="59" />
                          </div>
                        )
                    )}
                  </div>
                )}

                {menus?.map(({ navItems }, index) => (
                  <ul className={classes.linksWrapper} key={index}>
                    {navItems?.map(({ link }, index) => (
                      <li key={index}>
                        <CMSLink {...link} />
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            ) : null}
          </div>

          {copyright && (
            <div className={classes.copyright}>
              <RichText content={copyright} />
            </div>
          )}

          <div className={clsx(classes.copyright, classes.smallMargin)}>
            v{version} / {formVersion} / {generationDate} {updatedAt ? `/ ${updatedAt}` : ''}
          </div>
        </div>
      </footer>
    </>
  );
}
