import { useState } from 'react';
import NextImage from 'next/image';

import { ImageProps } from '../types';

import classes from './index.module.scss';
import { clsx } from 'clsx';
import { isProduction } from '@/utils/constants';

const domain = isProduction ? process.env.NEXT_PUBLIC_SITE_URL : process.env.NEXT_PUBLIC_CMS_URL;

export const Image = (props: ImageProps) => {
  const { className = '', src: srcFromProps, alt, width, height, loading } = props;
  const [isLoading, setIsLoading] = useState(true);
  const src = `${domain}${srcFromProps}`;

  return (
    <>
      <NextImage
        className={clsx(classes.image, { [classes.placeholder]: isLoading }, className, classes.themeLight)}
        src={src}
        alt={alt}
        onLoad={() => {
          setIsLoading(false);
        }}
        width={width}
        height={height}
        loading={loading || 'lazy'}
      />
    </>
  );
};
