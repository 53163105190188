import { getCookies } from 'cookies-next';

type Event = {
  eventId: string;
  eventName: string;
  eventCode: string;
  g1ConversionLabel: string;
  g2ConversionLabel: string;
  itemPrice: number;
};

export const EVENTS = Object.freeze<Record<string, Event>>({
  event_02: {
    eventId: 'event_02',
    eventName: 'button_hero',
    eventCode: 'event_02',
    g1ConversionLabel: 'Dq9oCNfBuZkDEPed-dAD',
    g2ConversionLabel: '-w0xCIn5gpAZELHo8swD',
    itemPrice: 0
  },
  event_11: {
    eventId: 'event_11',
    eventName: 'cta_middle',
    eventCode: 'event_11',
    g1ConversionLabel: 'iBJRCMa8g5AZEPed-dAD',
    g2ConversionLabel: '5lE1COqJg5AZELHo8swD',
    itemPrice: 0
  },
  event_17: {
    eventId: 'event_17',
    eventName: 'cta_bottom',
    eventCode: 'event_17',
    g1ConversionLabel: '3YYaCMm8g5AZEPed-dAD',
    g2ConversionLabel: 'ttHsCO2Jg5AZELHo8swD',
    itemPrice: 0
  },
  event_26: {
    eventId: 'event_26',
    eventName: 'cta_top',
    eventCode: 'event_26',
    g1ConversionLabel: 'DZmECM-8g5AZEPed-dAD',
    g2ConversionLabel: 'kUg-CPOJg5AZELHo8swD',
    itemPrice: 0
  },
  event_27: {
    eventId: 'event_27',
    eventName: 'cta_float',
    eventCode: 'event_27',
    g1ConversionLabel: 'tb-2CNK8g5AZEPed-dAD',
    g2ConversionLabel: 'XzMmCPaJg5AZELHo8swD',
    itemPrice: 0
  }
});

export const addToDataLayer = (data: Record<string, unknown>): void => {
  try {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  } catch (ex) {
    console.error(ex);
  }
};

export const addLeadEventToGTM = (eventId: string): void => {
  const event = EVENTS[eventId];

  if (!event) {
    return;
  }

  const cookies = getCookies();

  addToDataLayer({
    event: 'generate_lead',
    funnel: cookies['irs-funnel'],
    non_ecomm: {
      item_id: eventId,
      item_name: event.eventName,
      code: event.eventCode,
      g1_conversion_label: event.g1ConversionLabel,
      g2_conversion_label: event.g2ConversionLabel,
      item_price: 0
    }
  });
};
