'use client';

import { Image } from './Image';
import { MediaProps } from './types';

export const Media = (props: MediaProps) => {
  if (!props.source || typeof props.source === 'string') {
    return null;
  }

  const {
    source,
    width = Number(props.source.width),
    height = Number(props.source.height),
    alt = '',
    className,
    ...restProps
  } = props;

  if (typeof source === 'string') {
    return (
      <div className={className}>
        <Image src={source} width={width} height={height} alt={alt} />
      </div>
    );
  }

  if (!source.url) {
    return null;
  }

  return (
    <div className={className}>
      <Image
        {...restProps}
        alt={source.alt || alt}
        src={source.url}
        width={width}
        height={height}
        loading={source.lazy ? 'lazy' : 'eager'}
      />
    </div>
  );
};
