import React from 'react';

import { CustomRenderers, Serialize as SerializeContent } from './Serialize';

import classes from './index.module.scss';
import { RichTextType } from '@/utils/types';

export const RichText: React.FC<{
  className?: string;
  content: RichTextType;
  customRenderers?: CustomRenderers;
}> = ({ className, content, customRenderers }) => {
  if (!content) {
    return null;
  }

  return (
    <div className={[classes.richText, className].filter(Boolean).join(' ')}>
      <SerializeContent content={content.root?.children ?? []} customRenderers={customRenderers} />
    </div>
  );
};
