import { createContext, ReactNode } from 'react';
import { Header as HeaderType, Footer as FooterType, NotFound as NotFoundType } from '@/payload-types';

export interface Globals {
  header: HeaderType & {
    persistOnClickLogo?: boolean | null;
    hideHeaderButton?: boolean | null;
  };
  footer: FooterType;
  notFoundContent: NotFoundType;
  generationDate: string;
}
export const GlobalsContext = createContext<Globals | null>(null);

export const Globals = ({ children, globals }: { children: ReactNode; globals: Globals }) => {
  return <GlobalsContext.Provider value={globals}>{children}</GlobalsContext.Provider>;
};
